import React, { useEffect, useState } from 'react'
import { Amplify, API, graphqlOperation } from 'aws-amplify'
import { createTodo } from './graphql/mutations'
import { listTodos } from './graphql/queries'
import { withAuthenticator, Button, Heading } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsExports from "./aws-exports";
import { Storage } from "@aws-amplify/storage"

Amplify.configure(awsExports);



const App = ({ signOut, user }) => {
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [filename, setFilename] = useState();
  const [uploadComplete, setUploadComplete] = useState();
  const [uploadProgress, setUploadProgress] = useState();
  const [uploadError, setUploadError] = useState();

  function setInput(event) {
    setSelectedFile(event.target.files[0])
    setIsFilePicked(true);
  }
  
  const handleSubmission = () => {
    const newForm = new FormData();
    newForm.append('File', selectedFile);
    const file = newForm.get('File');

    let getFileName = file.name;
    let name = getFileName.substring(0, getFileName.length-4) + Date.now() + ".csv";
    setFilename(name);
    try{
      const result = Storage.put(name, file, {
        resumable: true,
        completeCallback: (event) => {
            setUploadComplete(event);
            console.log(`Successfully uploaded ${event.key}`);
        },
        progressCallback: (progress) => {
            setUploadProgress(progress);
            console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
        },
        errorCallback: (err) => {
            setUploadError(err);
            console.error('Unexpected error while uploading', err);
        }
      });
      console.log("Uploaded file");
    }
    catch(err){
      console.log("Error uploading: ", err)
    }
	};

  return (
    <div style={styles.container}>
      <Heading level={1}>Hello {user.username}</Heading>
      <Button onClick={signOut} style={styles.button}>Sign out</Button>
      <h2>File Upload</h2>
      <input
        onChange={setInput}
        style={styles.input}
        type="file"
        name="file"
        accept=".csv"
      />
      <button style={styles.button} onClick={handleSubmission}>Upload File</button>
      { uploadProgress ? (
        <div>
          <p>Uploaded: {uploadProgress.loaded}/{uploadProgress.total}</p>
          </div>
        ) : (
          <p></p>
        )
      }
      { uploadComplete ? (
        <div>
          <p>Successfully uploaded {uploadComplete.key}</p>
          </div>
        ) : (
          <p></p>
        )
      }  
      { uploadError ? (
        <div>
          <p>Upload Error: {uploadError}</p>
          </div>
        ) : (
          <p></p>
        )}
    </div>
  )
}

const styles = {
  container: { width: 400, margin: '0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 20 },
  todo: {  marginBottom: 15 },
  input: { border: 'none', backgroundColor: '#ddd', marginBottom: 10, padding: 8, fontSize: 18 },
  todoName: { fontSize: 20, fontWeight: 'bold' },
  todoDescription: { marginBottom: 0 },
  button: { backgroundColor: 'black', color: 'white', outline: 'none', fontSize: 18, padding: '12px 0px' }
}

export default withAuthenticator(App);